<template>
  <div
    class="mt-5 py-6 md:py-10 bg-white border-l-4 border-primary md:border-0 rounded-md flex flex-wrap items-center justify-evenly font-proximaLight"
  >
    <div
      class="w-full md:w-4/12 mb-2 md:mb-0 pl-4 md:border-l-4 border-primary relative"
    >
      <ul class="mb-3 flex">
        <li
          class="w-max px-2 mr-2 text-primary border border-primary rounded-md"
        >
          {{ breedNameSearch }}
        </li>
        <!-- <li
          class="w-max px-2 mr-2 text-primary border border-primary rounded-md"
        >
          Beauceron
        </li> -->
      </ul>
      <div class="flex items-start">
        <div class="flex flex-col items-center">
          <div class="w-7 h-7">
            <img
              v-if="data.profileImage"
              :src="data.profileImage"
              alt="profilepic-icon"
              class="w-7 h-7 rounded-full"
            />
            <div v-else class="w-7 h-7">
              <ProfileBreeder />
            </div>
            <!--  <img
            v-else
              src="@/assets/images/profilepic-icon.png"
              alt="profilepic-icon"
            /> -->
          </div>
          <div class="h-3 my-2"></div>
          <!--  <img
            class="my-2"
            src="@/assets/images/approval-shield-icon.png"
            alt="approval-shield-icon"
          /> -->
          <HeartIcon
            class="hidden md:block bg-lightGrey20 rounded-full p-1 cursor-pointer"
            width="24"
            height="24"
            :iconColor="favoriteColor"
          />
        </div>
        <div @click="goTo" class="ml-2 text-left">
          <div class="flex">
            <h5 class="text-primary font-bold cursor-pointer">
              {{ data.kennelName }}
            </h5>
            <div class="ml-2 flex items-center">
              <img
                src="@/assets/images/icons/trophy_icon20x20.png"
                alt="trophy_icon20x20.png"
              />
              <img
                src="@/assets/images/icons/badge_icon20x20.png"
                alt="badge_icon20x20.png"
              />
            </div>
          </div>
          <div class="text-sm">
            <span>{{ data.profile_streetAddress }}</span>
            <br />
            <div class="flex flex-row md:flex-col">
              <span>
                {{ data.profile_postalCode }} {{ data.profile_state }}
                {{ data.profile_country }}</span
              >
              <span class="pl-2 md:pl-0"
                >{{ data.dist_in_km.toFixed(2) }} km</span
              >
            </div>
          </div>
        </div>
      </div>
      <HeartIcon
        class="block md:hidden bg-lightGrey20 rounded-full p-1 absolute right-4 -top-3 cursor-pointer"
        width="24"
        height="24"
        :iconColor="favoriteColor"
        @click.native="clickfav"
      />
    </div>
    <div class="w-4/12 md:w-2/12">
      <span><!-- 2 Puppies --></span><br />
      <span><!-- 1/1 --></span>
    </div>
    <div
      class="w-4/12 md:w-3/12 border-r border-l md:border-none"
      :class="availabilityColor"
    >
      <span><!-- May 20th, 2022 --></span>
    </div>
    <div class="w-4/12 md:w-3/12 relative">
      <span><!-- €2000 - 2500 --></span>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
import HeartIcon from "../../icons/HeartIcon.vue";
import ProfileBreeder from "../../icons/ProfileBreeder.vue";

export default {
  props: ["data"],
  components: {
    StarRating,
    HeartIcon,
    ProfileBreeder,
  },
  data() {
    return {
      breedNameSearch: localStorage.getItem("breedNameSearch"),
      url: `/breederprofile/profile`, //businessprofileview
    };
  },
  computed: {
    availabilityColor() {
      //if (this.data.availability == "open") {
      if (1 == 1) {
        return "text-primary";
      } else if (this.data.availability == "closed") {
        return "text-red";
      } else {
        return "text-orange";
      }
    },
    favoriteColor() {
      if (this.data.is_friends_fav) {
        return "#09A89D";
      } else {
        return "#9ca3af";
      }
    },
  },
  methods: {
    async clickfav(data) {
      console.log(data);
      /*  data.is_friends_fav = !data.is_friends_fav;
      if (data.is_friends_fav == true) {
        await addToFavouriteServices(data.emailAddress);
      } else {
        await removeFromFavouriteServices(data.emailAddress);
      }
 */
      console.log("------------", data);
    },
    goTo() {
      localStorage.setItem('emailBreeder',this.data.emailAddress) ;
      this.$router.push({ path: this.url });
    },
  },
};
</script>

<style></style>
