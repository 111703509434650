<template>
  <div class="pb-12">
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          :titlemenu1="$t('community_section_title')"
          titlemenu2="Breeder Directory"
          :to2="'/browsebreederdirectory'"
          titlemenu3="Search Results"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <TopSectionBreederDirectoryResult @sortDataBy="sortDataBy" />
      <div
        class="flex flex-col md:flex-row items-start justify-between w-11/12 md:w-10/12"
      >
        <FilterSection class="hidden md:block" :data="filtredBreeds" />
        <ResultListSection :data="filtredBreeds" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import TopSectionBreederDirectoryResult from "./TopSectionBreederDirectoryResult.vue";
import FilterSection from "./FilterSection.vue";
import ResultListSection from "./ResultListSection.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    TopSectionBreederDirectoryResult,
    FilterSection,
    ResultListSection,
  },
  data() {
    return { val: 1 };
  },
  computed: {
    ...mapGetters({
      filtredBreeds: "breederdirectory/getfiltredBreeds",
    }),
   
  },
  created() {
    const breedName = localStorage.getItem("breedNameSearch");
    if (breedName) {
      this.getBreeds(breedName);
    } else {
      this.$router.push({ name: "BrowseBreederDirectory" });
    }
  },
  methods: {
    ...mapActions({
      getBreeds: "breederdirectory/getBreeds",
    }),
    sortDataBy(val) {
      this.val = val;
      console.log(val);
    },
  },
};
</script>

<style></style>
